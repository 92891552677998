.chat-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

.modal-container {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  box-shadow: 0px 0px 5px 2px rgb(80, 1, 26);
  border-radius: 8px;
  border: 2px solid rgb(80, 1, 26);
  height: 450px;
  width: 350px;
}

.modal-messages {
  display: inherit;
  flex-direction: column;
  width: 100%;
  padding: 4px;
  gap: 8px;
  flex: 1;
  overflow-y: scroll;
}

.message {
  padding: 4px;
  text-align: left;
  width: fit-content;
  max-width: 80%;
}

.user {
  justify-content: end;
  background: rgb(80, 1, 26);
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  border-radius: 15px;
}

.system {
  justify-content: start;
  padding: 4px 10px;
  border: 2px dashed rgb(80, 1, 26, 50%);
  margin: 0;
  border-radius: 10px;
}

.modal-close {
  /* position: absolute; */
  background-color: #f0f0f0;
  border: 0px;
  /* right: 3px;
  top: 9px; */
}

.modal-clear {
  background-color: #f0f0f0;
  border: 0px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: 2px solid black;
}

.modal-title {
  font-size: 22px;
  font-weight: 500;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saved {
  border: 2px solid rgb(80, 1, 26, 50%);
}